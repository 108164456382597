import React, { useContext, useEffect, lazy, Suspense } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import AuthContext from './authContext';
import AuthChecker from './AuthChecker';
// import ThankYou from './pages/ThankYou';

const ThankYou = lazy(() => import('./pages/ThankYou'));
const ChangePassword = lazy(() => import('./pages/AdminDashboard/Menus/users/ChangePassword'));
const EMAILOTP = lazy(() => import('./pages/EMAILOTP'));
const Login = lazy(() => import('./pages/AdminDashboard/AuthComponents/Login'));
const Dashboard = lazy(() => import('./pages/AdminDashboard/Layout/Dasboard'));
const ForgotPassword = lazy(() => import('./pages/AdminDashboard/AuthComponents/ForgotPassword'));
const NewPassword = lazy(() => import('./pages/AdminDashboard/AuthComponents/NewPassword'));
const ErrorPage = lazy(() => import('./pages/AdminDashboard/AuthComponents/Error'));
const Body = lazy(() => import('./pages/AdminDashboard/Layout/Body'));
const UserList = lazy(() => import('./pages/AdminDashboard/Menus/users/UserList'));
const Shimmer = lazy(() => import('./pages/AdminDashboard/Shimmer'));
const Agentmanagment = lazy(() => import('./pages/AdminDashboard/Menus/Agent/Agentmanagment'));
const Documents = lazy(() => import('./pages/AdminDashboard/Menus/Documents/Documents'));
const UploadDocument = lazy(() => import('./pages/AdminDashboard/Menus/Documents/UploadDocument'));
const AddAgent = lazy(() => import('./pages/AdminDashboard/Menus/Agent/AddAgent'));
const Institutionlist = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/Institutionlist'));
const ViewDocument = lazy(() => import('./pages/AdminDashboard/Menus/Documents/ViewDocument'));
const EditDocument = lazy(() => import('./pages/AdminDashboard/Menus/Documents/EditDocument'));
const UserRegistration = lazy(() => import('./pages/AdminDashboard/Menus/users/UserRegistration'));
const EditUser = lazy(() => import('./pages/AdminDashboard/Menus/users/EditUser'));
const EditInstituteUser = lazy(() => import('./pages/AdminDashboard/Menus/users/EditInstituteUser'));
const CreateInstitute = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/CreateInstitute'));
const EditInstitute = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/EditInstitute'));
const AddInstituteUser = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/AddInstituteUser'));
const ViewInstituteUser = lazy(() => import('./pages/AdminDashboard/Menus/Institutionlist/ViewInstituteUser'));
const OTP = lazy(() => import('./pages/OTP'));
const SharedDocuments = lazy(() => import('./pages/AdminDashboard/Menus/Documents/SharedDocuments'));
const SharedDocumentsInstitute = lazy(() => import('./pages/AdminDashboard/Menus/Documents/SharedDocumentsInstitute'));
const Logout = lazy(() => import('./pages/AdminDashboard/Layout/Logout'));

const appRouter = createHashRouter([
  {
    path: '/',
    element: <Suspense fallback={<div>Loading...</div>}><Login /></Suspense>
  },
  {
    path: '/share/document/:fileuuid/:institutionuuid',
    element: <Suspense fallback={<div>Loading...</div>}><OTP /></Suspense>
  },
  {
    path: '/directshare/document/:fileuuid/:email',
    element: <Suspense fallback={<div>Loading...</div>}><EMAILOTP /></Suspense>
  },
  {
    path: '/logout',
    element: <Suspense fallback={<div>Loading...</div>}><Logout /></Suspense>
  },
  {
    path: '/filedownlaod/:uuid',
    element: <Suspense fallback={<div>Loading...</div>}><OTP /></Suspense>
  },
  {
    path: '/dashboard',
    element: <Suspense fallback={<div>Loading...</div>}><Dashboard /></Suspense>,
    children: [
      {
        path: 'shimmer',
        element: <Suspense fallback={<div>Loading...</div>}><Shimmer /></Suspense>
      },
      {
        path: '',
        element: <Suspense fallback={<div>Loading...</div>}><Body /></Suspense>
      },
      {
        path: 'changePassword',
        element: <Suspense fallback={<div>Loading...</div>}><ChangePassword /></Suspense>
      },
      {
        path: 'documents/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><Documents /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'documents/:menuId/upload',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><UploadDocument /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'documents/:menuId/edit/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><EditDocument /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'documents/:menuId/view/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><ViewDocument /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutionlist/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><Institutionlist /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutionlist/:menuId/addinstitute',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><CreateInstitute /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutionlist/:menuId/edit/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><EditInstitute /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutionlist/:menuId/adduser/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><AddInstituteUser /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutionlist/:menuId/viewusers/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><ViewInstituteUser /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutionlist/:menuId/viewusers/:uuidI/edit/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><EditInstituteUser /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutionlist/:menuId/viewuser/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><EditInstitute /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'agentmanagment/add',
        element: <Suspense fallback={<div>Loading...</div>}><AddAgent /></Suspense>,
      },
      {
        path: 'fileview/fileid',
        element: <Suspense fallback={<div>Loading...</div>}><AddAgent /></Suspense>,
      },
      {
        path: 'agentmanagment',
        element: <Suspense fallback={<div>Loading...</div>}><Agentmanagment /></Suspense>,
      },
      {
        path: 'userlist/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><UserList /></Suspense>
          </AuthChecker>
        ),
      },
      {
        path: 'userlist/:menuId/add',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><UserRegistration /></Suspense>
          </AuthChecker>
        ),
      },
      {
        path: 'userlist/:menuId/edit/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><EditUser /></Suspense>
          </AuthChecker>
        ),
      },
      {
        path: 'sharedlist/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><SharedDocuments /></Suspense>
          </AuthChecker>
        ),
      },
      {
        path: 'sharedlist/:menuId/view/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><ViewDocument /></Suspense>
          </AuthChecker>
        )
      },
      {
        path: 'institutesharedlist/:menuId',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><SharedDocumentsInstitute /></Suspense>
          </AuthChecker>
        ),
      },
      {
        path: 'institutesharedlist/:menuId/view/:uuid',
        element: (
          <AuthChecker allowedRoles={['superadmin', 'employee', 'institueStaff']}>
            <Suspense fallback={<div>Loading...</div>}><ViewDocument /></Suspense>
          </AuthChecker>
        )
      },
    ]
  },
  {
    path: '/login',
    element: <Suspense fallback={<div>Loading...</div>}><Login /></Suspense>
  },
  {
    path: '/thankyou',
    element: <Suspense fallback={<div>Loading...</div>}><ThankYou /></Suspense>
  },
  {
    path: '/changepassword/:id',
    element: <Suspense fallback={<div>Loading...</div>}><NewPassword /></Suspense>
  },
  {
    path: '/forgotpassword',
    element: <Suspense fallback={<div>Loading...</div>}><ForgotPassword /></Suspense>
  },
  {
    path: '*', // Catch-all for unmatched routes
    element: <Suspense fallback={<div>Loading...</div>}><ErrorPage /></Suspense>
  }
]);

function App() {
  const authCtx = useContext(AuthContext);
  const authRole = authCtx.role;

  let decryptedRole;
  if (authRole !== 'superadmin' && authRole !== 'employee' && authRole !== 'institueStaff') {
    const decryptUserRole = (encryptedRole: string | CryptoJS.lib.CipherParams | null, key: string | CryptoJS.lib.WordArray) => {
      if (!encryptedRole) {
        return null; // or handle appropriately if null encryptedRole is not expected
      }
      const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
      return bytes.toString(CryptoJS.enc.Utf8).trim(); // Trim the decrypted role
    };

    decryptedRole = decryptUserRole(authRole, '98760');
  }

  let resizeTimeout: number | null;

  function handleResize() {
    if (!resizeTimeout) {
      resizeTimeout = requestAnimationFrame(() => {
        resizeTimeout = null;
      });
    }
  }

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="App">
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;
