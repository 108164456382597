import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from './authContext';
import CryptoJS from 'crypto-js';

const AuthChecker = ({ allowedRoles, children }: { allowedRoles: string[], children: React.ReactNode }) => {
  const authCtx = useContext(AuthContext);
  const authRole: any = authCtx.role;

  const decryptUserRole = (encryptedRole: string | CryptoJS.lib.CipherParams | null, key: string | CryptoJS.lib.WordArray) => {
    if (!encryptedRole) {
      return '';
    }
    const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8).trim();
    return decryptedString;
  };

  let userRole = authRole;

  if (authRole !== 'superadmin' && authRole !== 'employee' && authRole !== 'institueStaff') {
    userRole = decryptUserRole(authRole, '98760')?.replace(/"/g, '') || '';
  }

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default AuthChecker;
